import { TYPES } from '../../constants'
const { FETCH_DATA, SAVE_DATA, PUT_DATA, UPDATE_DATA, DELETE_DATA } = TYPES

const initialState = {
    objectName: ""
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DATA:
        case SAVE_DATA:
        case PUT_DATA:
        case UPDATE_DATA:
        case DELETE_DATA:
            return {
                ...state,
                objectName: action.objectName
            };    
        default:
            return state
    }
}

export default reducer
