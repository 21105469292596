import './assets/css/index.css'

import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import App from './App';
import store from './stores/store'
import reportWebVitals from './reportWebVitals'

//const store = configureStore()
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
      <App/>
  </Provider>
);

reportWebVitals()
