
import { notification } from 'antd';

export function handleSuccess(msg){
	notification.config({top: 70})
	notification.success({ message: msg, style: { backgroundColor: "#e8f5de"}})
}

export function handleErrors(e){
	let messageErrors = [];
	if(e.response){
		if(e.response.status === 403){
			messageErrors.push({type: "SERVER", message: "No tiene permiso para realizar esta acción."});
		}else if(e.response.status === 401){
			messageErrors.push({type: "SERVER", message: "No esta autorizado."});
		}else if(e.response.status === 409){
			messageErrors.push({type: "SERVER", message: "Ya esta asignado."});
		}else{
			if(e.response){
				if(!e.response.data){
					messageErrors.push({type: "SERVER", message: "Error con el servidor."});
				}else if(e.response.data.message){
					Object.keys(e.response.data.message).forEach( key => {
						messageErrors.push({type: key.toUpperCase(), message: e.response.data.message[key]});
					})
				}
			}else{
				messageErrors.push({type: "NET", message: "Error con la red."});
			}
		}
	}else{
		messageErrors.push({type: "SERVER", message: "Error con el servidor."});
	}	


	messageErrors.forEach(error => {
		notification.config({top: 80})
		notification.error({ message: `${error.message}`, style: { backgroundColor: "#f7e6e6"}})
	});
    
	return {
		isOk: false,
		errors: messageErrors
	}
}