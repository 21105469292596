const dev = {
    API_URL: "https://localhost:3000/v1",
    AUTH_URL: "https://localhost:3000",
    TOKEN_KEY: "ekauth:token",
    REFRESH_KEY: "ekauth:refreshToken",
    EXP_KEY: "ekauth:expiryDate",
    USER_INFO: "ekauth:user",
    PARTNER_ACTIVE: "ekapp:partner",
    PARTNER_ACTIVE_NAME: "ekapp:partner_name",
    UNMASK: "ekapp:unmask"
}
   
const test = {
    API_URL: "http://srv.gruporecover.com:8002/v1",
    AUTH_URL: "http://srv.gruporecover.com:8002",
    TOKEN_KEY: "auth:token",
    REFRESH_KEY: "auth:refreshToken",
    EXP_KEY: "auth:expiryDate",
    USER_INFO: "auth:user",
    PARTNER_ACTIVE: "app:partner",
    PARTNER_ACTIVE_NAME: "app:partner_name",
    UNMASK: "app:unmask"
}
  
const prod = {
    API_URL: "https://it.gruporecover.com:8002/v1",
    AUTH_URL: "https://it.gruporecover.com:8002",
    TOKEN_KEY: "auth:token",
    REFRESH_KEY: "auth:refreshToken",
    EXP_KEY: "auth:expiryDate",
    USER_INFO: "auth:user",
    PARTNER_ACTIVE: "app:partner",
    PARTNER_ACTIVE_NAME: "app:partner_name",
    UNMASK: "app:unmask"
}
  
const TYPES = {
    APP_INIT: "APP_INIT",
    APP_SET_USER: "APP_SET_USER",
    APP_SET_PARTNER: "APP_SET_PARTNER",
    AUTH_REQUEST: "AUTH_REQUEST",
    AUTH_SUCCESS: "AUTH_SUCCESS",
    AUTH_FAILURE: "AUHT_FAILURE",
    AUTH_CLEAN: "AUTH_CLEAN",
    AUTH_SET_TOKEN: "AUTH_SET_TOKEN",  
    FETCH_DATA: "FETCH_DATA",
    SAVE_DATA: "SAVE_DATA",
    PUT_DATA: "PUT_DATA",
    UPDATE_DATA: "UPDATE_DATA",
    DELETE_DATA: "DELETE_DATA",
    UNMASK: "UNMASK"
}
  

const device_types = {
    'desktop': 'escritorio', 
    'laptop': 'portatil',
    'server': 'servidor', 
    'computer_virtual': 'computadora virtual', 
    'server_virtual': 'servidor virtual', 
    'cloud': 'nube',
    'nas': 'nas', 
    'router': 'router', 
    'access_point': 'access point', 
    'switch': 'switch', 
    'phone': 'teléfono', 
    'print': 'impresora',
    'mcu': 'mcu'
}

const license_type = {
    'user': 'usuario',
    'device': 'dispositivo'
}

const spanish = {
    dayNames: ["Domingo","Lunes","Martes","Miercoles","Jueves","Viernes","Sabado"],
    monthNames: ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio", "Agosto","Septiembre","Octubre","Noviembre","Diciembre"]
}
  
const spaStrings = {
    'every': 'cada',
    'until': 'hasta',
    'day': 'dia',
    'days': 'dias',
    'week': 'semana',
    'weeks': 'semanas',
    'month': 'mes',
    'months': 'meses',
    'year': "año",
    'years': "años",
    'on': 'en',
    'for': 'por',
    'time': "vez",
    'times': "veces"
}
  
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },      
        md: { span: 5 },      
        lg: { span: 5 },      
        xl: { span: 5 },
        xxl: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
        md: { span: 19 },
        lg: { span: 19 },      
        xl: { span: 19 },      
        xxl: { span: 19 },
    },
}
  
const tailFormItemLayout = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 16, offset: 8},      
        md: { span: 16, offset: 8},
        lg: { span: 16, offset: 8},
        xl: { span: 16, offset: 8},
        xxl: { span: 16, offset: 8},
    }
}
  
const formItemOffset = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 19, offset: 5},
        md: { span: 19, offset: 5},      
        lg: { span: 19, offset: 5},      
        xl: { span: 19, offset: 5},      
        xxl: { span: 19, offset: 5},
    }
}
  
  
const selectVariables = () => {
    switch (process.env.NODE_ENV) {
        case 'production':
            return prod
        case 'test':
        case 'stage':
            return test
        default:
            return dev
    }
}

const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=')
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
                    return params
                }, {}
            )
        : {}
};

const styleContent = {
    content: {
		padding: 16, 
		background: '#fff', 
		minHeight: "80vh" 
	},
    header: {
        margin: "5px 0 0 0",
        padding: "5px 16px 0",
        background: "#fff"
    }
}



const toolbar_editor = [
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
]

const write_access = ['master','admin']
  
const VARS = selectVariables()

export {
    VARS, 
    TYPES, 
    spanish, 
    device_types, 
    license_type, 
    spaStrings, 
    formItemLayout, 
    tailFormItemLayout, 
    formItemOffset, 
    getQueryStringParams, 
    styleContent, 
    toolbar_editor, 
    write_access
}
  
  