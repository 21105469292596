import { TYPES } from '../../constants'
const { AUTH_REQUEST, AUTH_SUCCESS, AUTH_FAILURE, AUTH_CLEAN, AUTH_SET_TOKEN } = TYPES

const initialState = {
    token: null,
    expiryDate: null,
    isLoading: false,
    authenticate: false,
    errorMessage: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case AUTH_REQUEST: 
        return{
            ...state,
            isLoading: true,
            errorMessage: null
        }   
    case AUTH_SET_TOKEN:
        return {
            ...state,
            token: action.token,
            expiryDate: action.expiryDate
        }
    case AUTH_SUCCESS:
        return {
            ...state,
            authenticate: true,
            isLoading: false
        }
    case AUTH_FAILURE:
        return {
            ...state,
            authenticate: false,
            isLoading: false,        
            errorMessage: action.message
        }  
    case AUTH_CLEAN:
        return {
            ...state,
            token: null,
            expiryDate: null,
            isLoading: false,
            errorMessage: null
        }
    default:
        return state
    }
}

export default reducer
