import axios from 'axios'

import { TYPES, VARS } from '../constants'
import { authGetToken } from './'
import { handleErrors } from './notification'

const { FETCH_DATA, SAVE_DATA, PUT_DATA, UPDATE_DATA, DELETE_DATA } = TYPES

export const getData = (url,objectName,options={}) => 
    dispatch => {
        dispatch(apiAction(FETCH_DATA,objectName))
        return new Promise(async (resolve, reject) => {
            try{
                const token = await dispatch(authGetToken())
                options.headers = {'Authorization': `Bearer ${token}`}
                const response = await axios.get(`${VARS.API_URL}/${url}`, options)
                resolve(response.data)
            }catch(e){
                handleErrors(e)
                reject(e)
            }
        })
    }

export const exportData = (url,objectName,excel=false) => 
    dispatch => {
        dispatch(apiAction(FETCH_DATA, objectName))
        return new Promise(async (resolve, reject) => {
            try{
                const token = await dispatch(authGetToken())
                const headers = {'Authorization': `Bearer ${token}`}                
                const response = await fetch(`${VARS.API_URL}/${url}`, {method: 'GET', responseType: 'blob', headers})
                if(response.status === 200){
                    const blob = await response.blob()  
                    const uri = window.URL.createObjectURL(new Blob([blob]));
                    const date = new Date().getTime();
                    const link = document.createElement('a');
                    const filename = excel ? `${url.split('.')[0]}_${date/1000}.xlsx` : `${url.replace("/","_")}_${date/1000}.zip`;
                    link.href = uri;
                    link.setAttribute('download',filename);          
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);    
                    resolve(uri);    
                }else{
                    reject("Respond fail")
                }        
            }catch(e){
                handleErrors(e)
                reject(e)
            }
        })
    }
    
export const saveData = (url,data,objectName,options={}) => 
    dispatch => {
        dispatch(apiAction(SAVE_DATA,objectName))
        return new Promise(async (resolve, reject) => {
            try{
                const token = await dispatch(authGetToken())
                options.headers = {
                    'Authorization': `Bearer ${token}`
                }
                const response = await axios.post(`${VARS.API_URL}/${url}`, data, options)
                resolve(response.data)
            }catch(e){
                handleErrors(e)
                reject(e)
            }
        })
    }

export const putData = (url,data,objectName,options={}) => 
    dispatch => {
        dispatch(apiAction(PUT_DATA,objectName))
        return new Promise(async (resolve, reject) => {
            try{
                const token = await dispatch(authGetToken())
                options.headers = {'Authorization': `Bearer ${token}`}
                const response = await axios.put(`${VARS.API_URL}/${url}`, data, options)
                resolve(response.data)
            }catch(e){
                handleErrors(e)
                reject(e)
            }
        })
    }

export const updateData = (url,data,objectName,options={}) => 
    dispatch => {
        dispatch(apiAction(UPDATE_DATA,objectName))
        return new Promise(async (resolve, reject) => {
            try{
                const token = await dispatch(authGetToken())
                options.headers = {'Authorization': `Bearer ${token}`}
                const response = await axios.patch(`${VARS.API_URL}/${url}`, data, options)
                resolve(response.data)
            }catch(e){
                handleErrors(e)
                reject(e)
            }
        })
    }
    
export const deleteData = (url,objectName,options={}) => 
    dispatch => {
        dispatch(apiAction(DELETE_DATA,objectName))
        return new Promise(async (resolve, reject) => {
            try{
                const token = await dispatch(authGetToken())
                options.headers = {'Authorization': `Bearer ${token}`}
                const response = await axios.delete(`${VARS.API_URL}/${url}`, options)
                resolve(response.data)
            }catch(e){
                handleErrors(e)
                reject(e)
            }
        })
    }

export const apiAction = (type, objectName) => ({type, objectName})
