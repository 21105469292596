import { TYPES } from '../../constants'
const { APP_INIT, APP_SET_USER, APP_SET_PARTNER, UNMASK } = TYPES

const initialState = {
    init: false,
    user: {},
    partner: null,
    partner_name: null,
    unmask: 0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case APP_INIT:
        return {
            ...state,
            init: true
        }  
    case APP_SET_USER:
        return {
            ...state,
            user: action.user
        }
    case APP_SET_PARTNER:
        return {
            ...state,
            partner: action.partner,
            partner_name: action.partner_name
        }
    case UNMASK:
        return {
            ...state,
            unmask: action.unmask
        }
    default:
        return state
  }
}

export default reducer