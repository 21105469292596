
import './assets/css/app.css'

import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route  } from 'react-router-dom'
import { connect } from "react-redux"
import { isBrowser } from "react-device-detect";

import { ConfigProvider } from 'antd'
import 'moment/locale/es'
import esES from 'antd/locale/es_ES'
import loadable from '@loadable/component'

import {authAutoSignIn} from "./actions"
import PrivateRoute from './containers/private_route'

const Login = loadable(() => import("./containers/login"))
const Home = loadable(() => isBrowser ? import("./containers/home") : import("./containers/mobile"))

const App = ({onAutoSignIn, init}) => {

    const theme = {
        token:{      
            fontSize: 15,      
            colorPrimary: '#2F4C59',
            borderRadius: 5,
            colorLink: '#172D35',
            fontWeightStrong: 500,
            colorBorderSecondary: '#dadada'
        },
        components: {
            Layout:{
                siderBg: '#2F4C59',
                lightSiderBg: '#2F4C59'
            },
            Card: {
                headerBg: 'rgba(47, 76, 89,0.1)'
            },
            Menu:{         
                darkItemBg: '#2F4C59',
                darkSubMenuItemBg: '#2F4C59'
            },
            Descriptions: {
                labelBg: 'rgba(65, 114, 135, 0.2)'
            },
            Table: {
                headerBg: 'rgba(65, 114, 135, 0.2)',
                rowSelectedBg: 'rgba(140, 140, 140,0.3)',
                rowSelectedHoverBg: 'rgba(140, 140, 140,0.5)',
            },
            List: {
                headerBg: 'rgba(65, 114, 135, 0.2)'
            }
        }
    }

    useEffect(()=> {
        onAutoSignIn()
    })   

    return(      
        <ConfigProvider locale={esES} theme={theme}>
            <Router>
                {init && <Switch>
                    <Route path="/auth/:authType/:id?" component={Login} />
                    <PrivateRoute path="/" component={Home} />
                </Switch>}
            </Router>
        </ConfigProvider>
    )
    
}

const mapStateToProps = state => ({
    init: state.ui.init,
})

const mapDispatchToProps = dispatch => ({
    onAutoSignIn: () => dispatch(authAutoSignIn())
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
