
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import authReducer from "./reducers/auth"
import apiReducer from "./reducers/api"
import uiReducer from "./reducers/ui"

const middlewares = [thunk];

if (process.env.NODE_ENV === `testing`) {
  const { logger } = require(`redux-logger`);
  middlewares.push(logger);
}

const rootReducer = combineReducers({  
    ui: uiReducer,
    api: apiReducer,
    auth: authReducer
})

const store = configureStore({reducer: rootReducer, middleware: middlewares})
export default store

//const configureStore = () => createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
//export default configureStore




