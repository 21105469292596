import { TYPES, VARS } from '../constants'
const { APP_INIT, APP_SET_USER, APP_SET_PARTNER, UNMASK } = TYPES

export const initApp = () => {
    return {
        type: APP_INIT
    }
}

export const setActivePartner = (partner,partner_name) =>
    dispatch => {
        localStorage.setItem(VARS.PARTNER_ACTIVE, JSON.stringify(partner))
        localStorage.setItem(VARS.PARTNER_ACTIVE_NAME, JSON.stringify(partner_name))
        dispatch(setPartner(partner, partner_name))
    }

export const setProfileData = user => 
    dispatch => dispatch(setUser(user))


export const changeMask = value => 
    dispatch => {
        localStorage.setItem(VARS.UNMASK, value.toString())
        dispatch(setMask(value))
    }

const setPartner = (partner, partner_name) => {
    return {
        type: APP_SET_PARTNER,
        partner, partner_name
    }
}

const setUser = user => {
    return {
        type: APP_SET_USER,
        user
    }
}

const setMask = value => {
    return {
        type: UNMASK,
        unmask: value
    }
}